'use client';

import { useEffect, useRef, useCallback } from 'react';
import { usePathname } from 'next/navigation';
import { getSPSApiURLForTenant } from '@qb/frontend/utils/hosts/apiHost';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { useAuthUserContext } from '@/contexts/AuthUserContextProvider';
import { usePendingUserContext } from '@/contexts/PendingUserContextProvider';
import { TenantAlias } from '@/shared/tenants-alias-map';
const INACTIVITY_THRESHOLD_MS = 39000;

/**
 * Inspiration from: https://javascript.plainenglish.io/best-algorithm-to-implement-time-on-page-in-vanilla-javascript-using-sendbeacon-530bc8391ef5
 */
export const PageDurationTracker = ({
  tenantAlias
}: {
  tenantAlias: TenantAlias;
}) => {
  const pathname = usePathname();
  const prevPathname = useRef(pathname);
  const {
    authUser
  } = useAuthUserContext();
  const {
    pendingUser
  } = usePendingUserContext();
  const start = useRef<Date>(new Date());
  const isHalted = useRef(false);
  const haltedStartTime = useRef<number>(0);
  const haltedEndTime = useRef<number>(0);
  const totalHaltedTime = useRef<number>(0);
  const timeSpentScrollingMs = useRef<number>(0);
  const resetRefs = () => {
    start.current = new Date();
    isHalted.current = false;
    haltedStartTime.current = 0;
    haltedEndTime.current = 0;
    totalHaltedTime.current = 0;
    timeSpentScrollingMs.current = 0;
  };
  const savePageDuration = useCallback(() => {
    if (authUser || pendingUser) {
      const end = new Date();
      // Stop the halted timer so that totalHaltedTime gets the updated/correct value
      updateHaltedState();
      const timeSpentSec = Math.floor((end.getTime() - start.current.getTime() - timeSpentScrollingMs.current - totalHaltedTime.current) / 1000);
      if (timeSpentSec <= 0) return;
      const formData = new FormData();
      formData.append('url', window.location.href);
      formData.append('timeSpentSec', timeSpentSec.toString());
      if (authUser) {
        formData.append('userID', authUser.id.toString());
      }
      if (pendingUser) {
        formData.append('pendingUserID', pendingUser.id.toString());
      }
      navigator.sendBeacon(`${getSPSApiURLForTenant(tenantAlias, getHostFromWindow())}/analytics/savePageDuration`, formData);
      start.current = new Date();
    }
  }, [tenantAlias, authUser, pendingUser]);
  const updateHaltedState = () => {
    if (isHalted.current) {
      isHalted.current = false;
      haltedEndTime.current = Date.now();
      if (haltedStartTime.current) {
        totalHaltedTime.current = totalHaltedTime.current + (haltedEndTime.current - haltedStartTime.current);
      }
    } else {
      isHalted.current = true;
      haltedStartTime.current = Date.now();
    }
  };
  const handleScroll = useCallback(() => {
    // 18ms per scroll event roughly
    timeSpentScrollingMs.current = timeSpentScrollingMs.current + 18;
    updateHaltedState();
  }, []);
  useEffect(() => {
    window.addEventListener('beforeunload', savePageDuration);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('beforeunload', savePageDuration);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [savePageDuration, handleScroll]);
  useEffect(() => {
    const start = Date.now();
    setInterval(() => {
      if (Date.now() - start > INACTIVITY_THRESHOLD_MS) {
        // User hasn't done anything for INACTIVITY_THRESHOLD_MS so stop timer
        updateHaltedState();
      }
    }, INACTIVITY_THRESHOLD_MS);
  }, []);
  useEffect(() => {
    if (prevPathname.current && prevPathname.current !== pathname) {
      savePageDuration();
    }
    prevPathname.current = pathname;
    resetRefs();
  }, [pathname, savePageDuration]);
  return null;
};